











import { Component, Vue, Prop } from "vue-property-decorator";
import VPerson from './v-person.vue'

@Component({
  components: { VPerson }
})
export default class extends Vue {
  
  @Prop() data !: {title: string, dec:string, teams: any[]}
  @Prop({
    default: 2
  }) type !: number

}
