

















import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import VCard from '@/components/v-card.vue'
import VPersonModule from '@/components/v-person-module.vue'
import shopData from '../../assets/data/shop'

@Component({
  components: { vFreeApply,VBanner, VCard, VPersonModule }
})
export default class extends Vue {
  private bannerData = shopData.banner
  private cardsData = shopData.cards
  private expertsData = shopData.experts
}
