









import { Component, Vue, Prop } from "vue-property-decorator"
import { pxTorem } from '../utils/utils'

@Component
export default class extends Vue {
  
  @Prop() avatar !: string
  @Prop() name !: string
  @Prop() tag !: string
  @Prop() intro !: string
  @Prop({
    default: 2
  }) type !: number

  get bgColor(){
    return this.type == 1? '#F6F6F6' : 'white'
  }

  get avatarWidth(){
    return this.type == 1? pxTorem(100) : pxTorem(80)
  }

  get marginTop(){
    return this.type == 1 ? pxTorem(30) : "0"
  }

}
